// @TODO Legacy 코드이기 때문에 추후 제거해야함

import HttpError from "standard-http-error";
import { camelizeKeys, decamelizeKeys } from "humps";
import { normalize } from "normalizr";
import _ from "lodash";
import EventEmitter from "event-emitter";

const TIMEOUT = 100 * 1000; // 100s

/**
 * All HTTP errors are emitted on this channel for interested listeners
 */
export const errors = new EventEmitter();

/**
 * GET a path relative to API root url.
 * @param {String}  path Relative path to the configured API endpoint
 * @param {Object} schema Schema that normalizing body
 * @returns {Promise} of response body
 */
export async function get(path, schema, basicAuth, configHeader, fetchOptions) {
  return bodyOf(
    request("get", path, null, schema, basicAuth, configHeader, fetchOptions)
  );
}

/**
 * POST JSON to a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @param {Object} schema Schema that normalizing body
 * @returns {Promise}  of response body
 */
export async function post(
  path,
  body = {},
  schema,
  basicAuth,
  settingOptions = { decamelize: true, setLocale: true }
) {
  return bodyOf(
    request(
      "post",
      path,
      body,
      schema,
      basicAuth,
      undefined,
      undefined,
      settingOptions
    )
  );
}

/**
 * PUT JSON to a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 * @param {Object} schema Schema that normalizing body
 * @returns {Promise}  of response body
 */
export async function put(path, body, schema) {
  return bodyOf(request("put", path, body, schema));
}

/**
 * DELETE a path relative to API root url
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} schema Schema that normalizing body
 * @returns {Promise}  of response body
 */
export async function del(path, schema) {
  return bodyOf(request("delete", path, null, schema));
}

/**
 * Make arbitrary fetch request to a path relative to API root url
 * @param {String} method One of: get|post|put|delete
 * @param {String} path Relative path to the configured API endpoint
 * @param {Object} body Anything that you can pass to JSON.stringify
 */

let refreshingPromise = null;

const TOKEN_URL = "/auth/token";
const DRIVER_TOKEN_URL = "/auth/driver_token";
const REFRESH_TOKEN = "refresh_token";

export async function request(
  method,
  path,
  body,
  schema,
  basicAuth,
  configHeader,
  fetchOptions,
  settingOptions
) {
  try {
    const response = await sendRequest(
      method,
      path,
      body,
      basicAuth,
      configHeader,
      fetchOptions,
      settingOptions
    );
    const status = response.status;
    // if 401 refresh token
    // after refresh token retry
    if (status === 401) {
      // check response is basic or not
      if (
        (path === DRIVER_TOKEN_URL || path === TOKEN_URL) &&
        body &&
        body.grantType === REFRESH_TOKEN
      ) {
        throw new Error("incorrect_refresh_token");
      } else if (path !== TOKEN_URL && path !== DRIVER_TOKEN_URL) {
      }
    }
    // if error display error message

    // `fetch` promises resolve even if HTTP status indicates failure. Reroute
    // promise flow control to interpret error responses as failures
    const splitUrl = _.split(response.url, "/");
    const lastUrl = splitUrl[splitUrl.length - 1];
    if (lastUrl === "check_reset_token") {
      return handleResponse(path, schema, response);
    }
    if (status >= 400) {
      const message = await getErrorMessageSafely(response);
      if (!_.includes(["/presale1/get_total_progress"], path)) {
        // alert(message || "Bad Request.", "error");
        console.log(message || "Bad Request.", "error");
        // MvlToast(message || "Bad Request.", "error");
      }
      const error = new HttpError(status, message);

      // emit events on error channel, one for status-specific errors and other for all errors
      errors.emit(status.toString(), { path, message: error.message });
      errors.emit("*", { path, message: error.message }, status);

      throw error;
    }

    // if success parse response JSON
    // if parse error response raw string

    return handleResponse(path, schema, response);
  } catch (error) {
    throw error;
  }
}

/**
 * Takes a relative path and makes it a full URL to API server
 */
export function url(path) {
  let apiRoot = "";
  if (_.startsWith(path, "http")) {
    return path;
  }
  return path.indexOf("/") === 0 ? apiRoot + path : apiRoot + "/" + path;
}

/**
 * Constructs and fires a HTTP request
 */

const APPLICATION_JSON_TYPE = "application/json";

// file upload가 필요해서 content type을 null로 세팅해야되면 이곳에 추가해주기
const shouldContentTypeNull = (path) =>
  _.includes(["/drivers/me/document", "/users/me/kyc_records"], path);

// Basic auth인 path들 체크
const shouldForceBasic = (path) =>
  _.includes(
    [
      DRIVER_TOKEN_URL,
      TOKEN_URL,
      "/drivers/verify_phone",
      "/drivers/check_login",
      "/drivers/check_phone",
      "/drivers/request_phone_verify",
    ],
    path
  );

async function sendRequest(
  method,
  path,
  body,
  basicAuth,
  configHeader,
  fetchOptions,
  settingOptions
) {
  try {
    const endpoint = url(path);
    const forceBasic = shouldForceBasic(path);
    const accessToken = null;
    const forceJson = false;
    const contentType = shouldContentTypeNull(path)
      ? null
      : APPLICATION_JSON_TYPE;

    const headers = await getRequestHeaders(
      accessToken,
      forceBasic,
      forceJson,
      contentType,
      basicAuth,
      configHeader
    );
    const options = body
      ? {
          method,
          headers,
          body:
            contentType === APPLICATION_JSON_TYPE
              ? JSON.stringify(
                  settingOptions.camelize ? decamelizeKeys(body) : body
                )
              : body,
        }
      : { method, headers };

    return fetch(endpoint, { ...options, ...fetchOptions });
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * Receives and reads a HTTP response
 */
async function handleResponse(path, schema, response) {
  try {
    // parse response text
    const responseBody = await response.text();
    let body = responseBody ? camelizeKeys(JSON.parse(responseBody)) : null;
    if (body && schema) {
      body = normalize(body, schema);
    }

    return {
      status: response.status,
      headers: response.headers,
      body,
    };
  } catch (e) {
    throw e;
  }
}

async function getRequestHeaders(
  token,
  forceBasic = false,
  forceJson = false,
  contentType = "",
  basicAuth,
  configHeader
) {
  let headers = { Accept: APPLICATION_JSON_TYPE };
  if (!_.isEmpty(forceJson)) {
    _.assign(headers, { "Content-Type": APPLICATION_JSON_TYPE });
  } else if (contentType) {
    _.assign(headers, { "Content-Type": contentType });
  }

  if (basicAuth) {
    _.assign(headers, { Authorization: `Basic ${basicAuth}` });
  } else if (token && !forceBasic) {
    _.assign(headers, { Authorization: `Bearer ${token}` });
  } else {
    _.assign(headers, {
      Authorization: "Basic dGFkYV9kcml2ZXJfYXBwOnRhZGFfZHJpdmVyX3NlY3JldDc=",
    });
  }

  if (configHeader) {
    _.assign(headers, { ...configHeader });
  }

  return headers;
}

// try to get the best possible error message out of a response
// without throwing errors while parsing
async function getErrorMessageSafely(response) {
  try {
    const body = await response.text();
    if (!body) {
      return "";
    }

    // Optimal case is JSON with a defined message property
    const payload = camelizeKeys(JSON.parse(body));
    if (payload && payload.message) {
      return payload.message;
    }

    // Should that fail, return the whole response body as text
    return body;
  } catch (e) {
    // Unreadable body, return whatever the server returned
    return response.statusText;
  }
}

async function bodyOf(requestPromise) {
  try {
    const response = await requestPromise;
    return (response && response.body) || null;
  } catch (e) {
    throw e;
  }
}
