import { LocaleType, Region, RideStatusType, valueOf } from './types';
import { SharedRideInfo, SharedRoutingResultDto } from '../types';
import { getFormattingTime } from '../utils';

export const TOKEN_KEYS = {
  ride: window.btoa('mvl-homepage:mvl-homepage-secret0'),
};

export const RIDE_TYPE = {
  APP_METER_RIDE: 0,
  CAR_METER_RIDE: 1,
  BASE_RIDE: 10,
  DELIVERY: 100,
  DOD_RIDE: 200,
  VALET_RIDE: 201,
} as const;

export const REGION = {
  SG: 'SG',
  VN: 'VN',
  KH: 'KH',
  KR: 'KR',
  ET: 'ET',
  ID: 'ID',
  HK: 'HK',
  TH: 'TH',
  GLOBAL: 'GLOBAL',
} as const;

export const RIDE_STATUS = {
  RENTAL_STATUS_NOT_MATCHED: 10,
  RENTAL_STATUS_MATCHED: 12,
  RENTAL_STATUS_CONFIRMED: 15,
  RENTAL_STATUS_PENDING: 20,
  RENTAL_STATUS_ASSIGNED: 40,
  RENTAL_STATUS_PICKUP: 50,
  RENTAL_STATUS_PICKUP_ARRIVED: 55,
  RENTAL_STATUS_INUSE: 60,
  RENTAL_STATUS_INUSE_WITH_CANCEL_PENDING: 65,
  RENTAL_STATUS_FINISHED: 70,
  RENTAL_STATUS_USER_CANCELED: 100,
  RENTAL_STATUS_COMPANY_CANCELED: 300,
  RENTAL_STATUS_DRIVER_CANCELED: 310,
  RENTAL_STATUS_EXPIRED: 400,
} as const;

export const CANCELED_RIDE_STATUS_LIST: RideStatusType[] = [
  RIDE_STATUS.RENTAL_STATUS_USER_CANCELED,
  RIDE_STATUS.RENTAL_STATUS_DRIVER_CANCELED,
  RIDE_STATUS.RENTAL_STATUS_COMPANY_CANCELED,
];

export const RIDE_STATUS_MESSAGE: Record<
  RideStatusType,
  (
    rideInfo: SharedRideInfo,
    rideRouting: SharedRoutingResultDto | null,
    additionalEstimtedTime?: number
  ) => { key: string; data?: Record<string, string | number | null> }
> = {
  [RIDE_STATUS.RENTAL_STATUS_NOT_MATCHED]: () => ({ key: '' }),
  [RIDE_STATUS.RENTAL_STATUS_MATCHED]: () => ({ key: '' }),
  [RIDE_STATUS.RENTAL_STATUS_CONFIRMED]: () => ({ key: '' }),
  [RIDE_STATUS.RENTAL_STATUS_PENDING]: () => ({ key: '' }),
  [RIDE_STATUS.RENTAL_STATUS_ASSIGNED]: () => ({ key: '' }),
  [RIDE_STATUS.RENTAL_STATUS_PICKUP]: (rideInfo) => ({
    key: 'text_message_pickup_waiting',
    data: { riderName: rideInfo.riderName },
  }),
  [RIDE_STATUS.RENTAL_STATUS_PICKUP_ARRIVED]: () => ({
    key: 'text_message_pickup_arrived',
  }),
  [RIDE_STATUS.RENTAL_STATUS_INUSE]: (rideInfo, rideRouting, additionalEstimtedTime) => {
    if (!rideRouting) {
      return {
        key: 'text_message_na_inuse',
        data: {
          riderName: rideInfo.riderName,
          time: null,
        },
      };
    }

    return {
      key: 'text_message_inuse',
      data: {
        riderName: rideInfo.riderName,
        time: Math.ceil(rideRouting.durationSeconds / 60) + (additionalEstimtedTime ?? 0),
      },
    };
  },
  [RIDE_STATUS.RENTAL_STATUS_INUSE_WITH_CANCEL_PENDING]: () => ({ key: '' }),
  [RIDE_STATUS.RENTAL_STATUS_FINISHED]: (rideInfo, rideRouting) => {
    if (!rideRouting) {
      return {
        key: 'text_message_na_finished',
        data: {
          riderName: rideInfo.riderName,
          time: null,
        },
      };
    }

    return {
      key: 'text_message_finished',
      data: {
        riderName: rideInfo.riderName,
        time: getFormattingTime(rideInfo.dropOffTime, 'HH:mm'),
      },
    };
  },
  [RIDE_STATUS.RENTAL_STATUS_USER_CANCELED]: () => ({
    key: 'text_message_cancelled',
  }),
  [RIDE_STATUS.RENTAL_STATUS_COMPANY_CANCELED]: () => ({
    key: 'text_message_cancelled',
  }),
  [RIDE_STATUS.RENTAL_STATUS_DRIVER_CANCELED]: () => ({
    key: 'text_message_cancelled',
  }),
  [RIDE_STATUS.RENTAL_STATUS_EXPIRED]: () => ({ key: '' }),
};

export const CAR_TYPE = {
  SEDAN: 0,
  VAN: 1,
  BUSINESS_VAN: 4,
  BLACK_SEDAN: 13,
  TUKTUK: 1000,
  BIKE: 1001,
  REMORQUE: 1002,
  FOOT: 2000,
  BUS: 3000,
  MINI_BUS: 3001,
  TAXI_STANDARD: 10000,
  TAXI_STANDARD_LARGE: 10001,
  TAXI_PREMIUM: 10002,
  TAXI_PREMIUM_LARGE: 10003,
  DELIVERY_VAN: 20000,
  DELIVERY_BIKE: 20001,
  DELIVERY_CAR: 20002,
  ONION_TUKTUK: 30000,
} as const;

export const DEFAULT_CENTER = { lat: 1.332416, lng: 103.8903482 };

export const DEFAULT_CENTER_OF_REGION: Record<Region, google.maps.LatLngLiteral> = {
  [REGION.SG]: { lat: 1.332416, lng: 103.8903482 },
  [REGION.VN]: { lat: 10.7963254, lng: 106.7314253 },
  [REGION.KH]: { lat: 11.5639796, lng: 104.9304278 },
  [REGION.TH]: { lat: 13.762491, lng: 100.497281 },
  [REGION.ET]: DEFAULT_CENTER,
  [REGION.HK]: { lat: 22.3226, lng: 114.175802 },
  [REGION.ID]: DEFAULT_CENTER,
  [REGION.KR]: DEFAULT_CENTER,
  [REGION.GLOBAL]: DEFAULT_CENTER,
};

export const LOCALE_TYPE = {
  EN: 'en',
  KO: 'ko',
  ZH_HANS: 'zh_hans',
  ZH_HANT: 'zh_hant',
  KM: 'km',
  VN: 'vn',
  TH: 'th',
} as const;

export const REGION_LOCALE: Record<string, LocaleType> = {
  [REGION.SG]: LOCALE_TYPE.EN,
  [REGION.VN]: LOCALE_TYPE.VN,
  [REGION.KH]: LOCALE_TYPE.KM,
  [REGION.KR]: LOCALE_TYPE.KO,
  [REGION.TH]: LOCALE_TYPE.TH,
  [REGION.HK]: LOCALE_TYPE.EN,
};

export const SUPPORT_LANGUAGES: string[] = [LOCALE_TYPE.EN, LOCALE_TYPE.KM, LOCALE_TYPE.VN, LOCALE_TYPE.TH, LOCALE_TYPE.ZH_HANS, LOCALE_TYPE.ZH_HANT];

export const MAP_STYLE = [
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        color: '#c7c7c7',
      },
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ebebeb',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'transit.station.bus',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.station.bus',
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 50,
      },
    ],
  },
  {
    featureType: 'transit.station.rail',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        color: '#acdbfd',
      },
    ],
  },
];

export const SMALL_SHEET_SIZE = 260;

export const PRODUCT_TYPE = {
  PH: 10,
  TAXI: 11,
  ANYTADA: 12,
  STUDENT: 13,
  EV: 14, //IONTada
  DELIVERY: 20,
  DOD: 30,
  VALET: 31,
} as const;

// Currently this is only used in HK
export const TAXI_TYPE = {
  URBAN_TAXI: 'URBAN_TAXI',
  NEW_TERRITORIES_TAXI: 'NEW_TERRITORIES_TAXI',
  LANTAU_TAXI: 'LANTAU_TAXI',
} as const;

export const BIKE_WITH_HUMAN_ICON_REQUIRED_REGIONS = [REGION.KH, REGION.VN, REGION.TH];

export const ALL_TAXI_CAR_TYPES = [CAR_TYPE.TAXI_STANDARD, CAR_TYPE.TAXI_PREMIUM, CAR_TYPE.TAXI_STANDARD_LARGE, CAR_TYPE.TAXI_PREMIUM_LARGE];
